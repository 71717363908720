import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapBroadway,
  locationMapMelville,
  mobileMapBroadway,
  mobileMapMelville,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'pdH3lMpkmS9UUwzQGxap') {
          locationLink = 'https://g.page/sushicalidowntown?share'
          mapImage = locationMapMelville
          mapImageMobile = mobileMapMelville
        } else {
          locationLink = 'https://g.page/sushicalivan?share'
          mapImage = locationMapBroadway
          mapImageMobile = mobileMapBroadway
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Sushi California Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Sushi California Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
