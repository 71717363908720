export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1588575172/chickochicken/orderDeliveryButton.png'
export const aboutMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1613157983/sushicali/about_Melville_wpglku.jpg'
export const aboutBroadway =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1613157985/sushicali/about1_Broadway_zf8j1c.jpg'
export const contactbg =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/contactbg.jpg'
export const downloadAppButton =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1611633022/sushicalifornia/orderDeliveryButton2.png'
export const foodGallery =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612246878/sushicali/foodGallery_yxlqkk.jpg'
export const heroMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247284/sushicali/heroMelville_bf4pbd.jpg'
export const heroBroadway =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247267/sushicali/heroBroadway_eyc5uj.jpg'
export const heroOther =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1669781036/sushicali/otherHero.jpg'
export const locationMapBroadway =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247341/sushicali/locationMapBroadway_fdthnp.jpg'
export const locationMapMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247341/sushicali/locationMapMelville2_elgk4q.jpg'
export const logo =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/logo.png'
export const logoBlack =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/logoBlack.png'
export const mobileAboutMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1613158284/sushicali/mobileAbout_Melville_wgvgzq.jpg'
export const mobileAboutBroadway =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1613158285/sushicali/mobileAbout_Broadway_sfasvk.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/mobilecontactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612246878/sushicali/mobileGallery_u0ez9h.jpg'
export const mobileHeroMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247813/sushicali/mobileHeroMelville_dw36l6.jpg'
export const mobileHeroBroadway =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612247813/sushicali/mobileHeroBroadway_ixnwmx.jpg'
export const mobileHeroOther =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1669783904/sushicali/mobileHero.jpg'
export const mobileMapBroadway =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/mobileMapBroadway.jpg'
export const mobileMapMelville =
  'https://res.cloudinary.com/sushicali/image/upload/f_auto,q_auto:best/v1612469116/sushicali/mobileMapMelville_oxfetg.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/mobilePromotion.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1611633022/sushicalifornia/orderPickupButton2.png'
export const promotions =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/promotions.jpg'
