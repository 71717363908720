import React from 'react'
import {
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  mobileAboutMelville,
  mobileAboutBroadway,
  aboutMelville,
  aboutBroadway,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let about = null
        let mobileAbout = null
        if (locationId === 'pdH3lMpkmS9UUwzQGxap') {
          about = aboutMelville
          mobileAbout = mobileAboutMelville
        } else {
          about = aboutBroadway
          mobileAbout = mobileAboutBroadway
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={mobileAbout} w="100%" alt="About" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1400px">
                <CFImage src={about} w="100%" alt="About1" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
